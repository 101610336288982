



































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BrandAutocompletesService } from '@/api/braendz';
import { BusyList } from '@/models/Busy';
import { AutocompleteMode, BrandStateCategory, FilterableField, SearchableField } from '@/models/Query';
import { VCombobox } from 'vuetify/lib';

@Component({
    model: {
        prop: "model",
        event: "inputChanged"
    }
})
export default class BrandNameCombobox extends Vue {
    // Fields
    // public substring: string | null = null;
    public autocompleteList = new BusyList<string>();

    // Component Properties:
    @Prop({ required: true })
    public model!: string | null;

    @Prop({ required: false })
    public solo?: boolean;

    @Prop({ required: false })
    public outlined?: boolean;

    @Prop({ required: false })
    public label?: string;

    @Prop({ required: false })
    public icon?: string;

    @Prop({ required: false })
    public backgroundColor?: string;

    @Prop({ required: false })
    public busy?: boolean;

    // Getter & Setter
    public get input(): string | null {
        return this.model;
    }
    public set input(value: string | null) {
        this.$emit('inputChanged', value);
    }

    // Component lifecycle methods:

    // Methods:
    public onEnterPressed(): void {
        this.clearAutocomplete();
    }
    public onInput(): void {
        this.clearAutocomplete();
    }

    public async autocomplete(substring: string | undefined | null): Promise<void> {
        if(this.model === substring) {
            return;
        }

        if (this.autocompleteList.isBusy) {
            return;
        }

        if (!substring || substring.length < 3) {
            this.autocompleteList.list = [];
            return;
        }

        await this.autocompleteList.load(async () => {
            if (substring) {
                const result = await BrandAutocompletesService.autocompleteBrandNames(substring, {  
                    fields: [ SearchableField.Name ],
                    filters: [
                        { field: FilterableField.BrandStateCategory, value: BrandStateCategory.New },
                        { field: FilterableField.BrandStateCategory, value: BrandStateCategory.Alive }
                    ],
                    size: 3,
                    mode: AutocompleteMode.OneTermWithContext,
                    useFuzzyMatching: true
                });
                return result;
            }
            else {
                return [];
            }
        });
    }

    public clearAutocomplete() {
        this.autocompleteList.list = [];
    }

    public closeMenu() {
        if(this.$refs.combobox) {
            if('isMenuActive' in this.$refs.combobox) {
                this.$refs.combobox.isMenuActive = false;
            }

            if('isActive' in this.$refs.combobox) {
                this.$refs.combobox.isActive = false;
            }
        }
    }

    public filter(item: any, queryText: string, itemText: string): boolean {
        return true;
    }
}
