var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "", flat: "", color: "transparent", width: _vm.width } },
    [
      _vm.titleTop
        ? _c("span", { staticClass: "d-block caption" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
      _vm.$slots.default
        ? [_vm._t("default")]
        : typeof _vm.value === "boolean" || typeof _vm.value2 === "boolean"
        ? [
            typeof _vm.value === "boolean"
              ? [
                  _vm.value
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("brand.details.true")))]
                      )
                    : _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("brand.details.false")))]
                      ),
                ]
              : _vm.value
              ? [
                  _vm.value instanceof Date
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$d(_vm.value)))]
                      )
                    : typeof _vm.value === "number"
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$n(_vm.value)))]
                      )
                    : _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.value))]
                      ),
                ]
              : _vm._e(),
            typeof _vm.value2 === "boolean"
              ? [
                  _vm.value2
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("brand.details.true")))]
                      )
                    : _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("brand.details.false")))]
                      ),
                ]
              : _vm.value2
              ? [
                  _vm.value2 instanceof Date
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$d(_vm.value2)))]
                      )
                    : typeof _vm.value2 === "number"
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$n(_vm.value2)))]
                      )
                    : _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.value2))]
                      ),
                ]
              : _vm._e(),
          ]
        : _vm.value || _vm.value2
        ? [
            _vm.value
              ? [
                  _vm.value instanceof Date
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$d(_vm.value)))]
                      )
                    : typeof _vm.value === "number"
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$n(_vm.value)))]
                      )
                    : _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.value))]
                      ),
                ]
              : _vm._e(),
            _vm.value2
              ? [
                  _vm.value2 instanceof Date
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$d(_vm.value2)))]
                      )
                    : typeof _vm.value2 === "number"
                    ? _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$n(_vm.value2)))]
                      )
                    : _c(
                        "span",
                        {
                          class: {
                            "d-block": !_vm.wrap,
                            "text-truncate": !_vm.wrap,
                            "body-1": true,
                          },
                        },
                        [_vm._v(_vm._s(_vm.value2))]
                      ),
                ]
              : _vm._e(),
          ]
        : _vm.alternative1
        ? [
            _vm.alternative1 instanceof Date
              ? _c(
                  "span",
                  {
                    class: {
                      "d-block": !_vm.wrap,
                      "text-truncate": !_vm.wrap,
                      "body-1": true,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$d(_vm.alternative1)))]
                )
              : typeof _vm.alternative1 === "number"
              ? _c(
                  "span",
                  {
                    class: {
                      "d-block": !_vm.wrap,
                      "text-truncate": !_vm.wrap,
                      "body-1": true,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$n(_vm.alternative1)))]
                )
              : _c(
                  "span",
                  {
                    class: {
                      "d-block": !_vm.wrap,
                      "text-truncate": !_vm.wrap,
                      "body-1": true,
                    },
                  },
                  [_vm._v(_vm._s(_vm.alternative1))]
                ),
          ]
        : _vm.alternative2
        ? [
            _vm.alternative2 instanceof Date
              ? _c(
                  "span",
                  {
                    class: {
                      "d-block": !_vm.wrap,
                      "text-truncate": !_vm.wrap,
                      "body-1": true,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$d(_vm.alternative2)))]
                )
              : typeof _vm.alternative2 === "number"
              ? _c(
                  "span",
                  {
                    class: {
                      "d-block": !_vm.wrap,
                      "text-truncate": !_vm.wrap,
                      "body-1": true,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$n(_vm.alternative2)))]
                )
              : _c(
                  "span",
                  {
                    class: {
                      "d-block": !_vm.wrap,
                      "text-truncate": !_vm.wrap,
                      "body-1": true,
                    },
                  },
                  [_vm._v(_vm._s(_vm.alternative2))]
                ),
          ]
        : _c(
            "span",
            { staticClass: "d-block text-truncate body-1 text--disabled" },
            [_vm._v(_vm._s(_vm.$t("brand.details.notAvailable")))]
          ),
      !_vm.titleTop && _vm.titleBottom
        ? _c("span", { staticClass: "d-block caption" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }