var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    ref: "combobox",
    attrs: {
      solo: _vm.solo,
      outlined: _vm.outlined,
      label: _vm.label,
      items: _vm.autocompleteList.list,
      loading: _vm.autocompleteList.isBusy || _vm.busy,
      "prepend-inner-icon": _vm.icon,
      filter: _vm.filter,
      "return-object": "",
      "hide-details": "",
      "hide-no-data": "",
      "menu-props": {
        dark: false,
        rounded: true,
        closeOnClick: true,
        closeOnContentClick: true,
        contentClass: "dropdown-menu scrollbar",
        contentProps: { style: { "--dropdown-bg": _vm.backgroundColor } },
      },
      "background-color": _vm.backgroundColor,
    },
    on: {
      input: _vm.onInput,
      "update:search-input": _vm.autocomplete,
      keydown: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        return _vm.onEnterPressed.apply(null, arguments)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (ref) {
          var item = ref.item
          var on = ref.on
          var attrs = ref.attrs
          return [
            _c(
              "v-list-item",
              _vm._g(
                _vm._b(
                  { attrs: { dense: "", height: 10 } },
                  "v-list-item",
                  attrs,
                  false
                ),
                on
              ),
              [
                _c(
                  "v-list-item-content",
                  { staticClass: "pa-0" },
                  [
                    _c("v-list-item-title", {
                      domProps: { textContent: _vm._s(item) },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.input,
      callback: function ($$v) {
        _vm.input = $$v
      },
      expression: "input",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }