var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "section",
        {
          staticClass: "theme--light default-vertical-padding",
          attrs: { id: "brands-de" },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "headline",
                          attrs: {
                            row: "",
                            "justify-center": "",
                            "data-aos": "fade-down",
                          },
                        },
                        [
                          _c("h1", { staticClass: "title" }, [
                            _vm._v(" Subscribe "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-stepper",
                        {
                          attrs: { flat: "" },
                          model: {
                            value: _vm.step,
                            callback: function ($$v) {
                              _vm.step = $$v
                            },
                            expression: "step",
                          },
                        },
                        [
                          _c(
                            "v-stepper-header",
                            { staticClass: "elevation-0" },
                            [
                              _c(
                                "v-stepper-step",
                                {
                                  attrs: { complete: _vm.step > 1, step: "1" },
                                },
                                [_vm._v(" Cart ")]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-stepper-step",
                                {
                                  attrs: { complete: _vm.step > 2, step: "2" },
                                },
                                [_vm._v(" Billing Information ")]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-stepper-step",
                                {
                                  attrs: { complete: _vm.step > 3, step: "3" },
                                },
                                [_vm._v(" Payment ")]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-stepper-step",
                                {
                                  attrs: { complete: _vm.step > 4, step: "4" },
                                },
                                [
                                  !_vm.result
                                    ? _c("span", [_vm._v("Completed")])
                                    : _vm.result === "Succeeded"
                                    ? _c("span", [_vm._v("Confirmation")])
                                    : _vm.result === "Cancelled"
                                    ? _c("span", [_vm._v("Cancelled")])
                                    : _c("span", [_vm._v("Failed")]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-items",
                            [
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "1" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-center" },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "pa-10",
                                          attrs: {
                                            elevation: "0",
                                            color: "white",
                                          },
                                        },
                                        [
                                          _vm.isSubscriptionUpdate
                                            ? _c(
                                                "v-alert",
                                                {
                                                  staticClass: "mt-2 mb-4",
                                                  attrs: {
                                                    text: "",
                                                    color: "#43a047",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "This will update your current PayPal subscription."
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-card-title",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [
                                                  _vm._v(
                                                    "fa-light fa-cart-shopping"
                                                  ),
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v("Selected Subscription"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list",
                                            {
                                              attrs: {
                                                "two-line": "",
                                                flat: "",
                                                color: "transparent",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  model: {
                                                    value: _vm.selectedProduct,
                                                    callback: function ($$v) {
                                                      _vm.selectedProduct = $$v
                                                    },
                                                    expression:
                                                      "selectedProduct",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.products.list,
                                                  function (product) {
                                                    return _c("v-list-item", {
                                                      key: product.key,
                                                      attrs: {
                                                        value: product,
                                                        disabled:
                                                          _vm.getSubscriptionTransformation(
                                                            product
                                                          ) === "Downgrade" ||
                                                          _vm.getSubscriptionTransformation(
                                                            product
                                                          ) === "Same",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (ref) {
                                                              var active =
                                                                ref.active
                                                              return [
                                                                _c(
                                                                  "v-list-item-action",
                                                                  [
                                                                    active
                                                                      ? _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "fa-light fa-chevron-right"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                product.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm.getSubscriptionTransformation(
                                                                          product
                                                                        ) ===
                                                                        "Upgrade"
                                                                          ? _c(
                                                                              "v-chip",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-3",
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "",
                                                                                    "x-small":
                                                                                      "",
                                                                                    color:
                                                                                      "#43a047",
                                                                                    "text-color":
                                                                                      "white",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Upgrade"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-subtitle",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            product.description
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item-action",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-action-text",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            product.price
                                                                          ) +
                                                                            " €"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.selectedProduct &&
                                          _vm.selectedProduct
                                            .includedPackages &&
                                          _vm.selectedProduct.includedPackages >
                                            0
                                            ? [
                                                _c(
                                                  "v-card-title",
                                                  { staticClass: "mt-10" },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { left: "" } },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-gear"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.selectedProduct
                                                      .includedPackages === 1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "One office included. Please choose:"
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .selectedProduct
                                                                .includedPackages
                                                            ) +
                                                              " offices included. Please choose:"
                                                          ),
                                                        ]),
                                                  ],
                                                  1
                                                ),
                                                _c("v-divider"),
                                                _c(
                                                  "v-list",
                                                  {
                                                    attrs: {
                                                      "two-line": "",
                                                      flat: "",
                                                      color: "transparent",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-group",
                                                      {
                                                        attrs: { multiple: "" },
                                                        model: {
                                                          value:
                                                            _vm.selectedPackages,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedPackages =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedPackages",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.packages.list,
                                                        function (pkg) {
                                                          return _c(
                                                            "v-list-item",
                                                            {
                                                              key: pkg.key,
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isPackageSelectionDisabled(
                                                                    pkg
                                                                  ),
                                                                value: pkg,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "default",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var active =
                                                                          ref.active
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-action",
                                                                            [
                                                                              _c(
                                                                                "v-checkbox",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "input-value":
                                                                                        active,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      pkg.name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-list-item-subtitle",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      pkg.description
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          _c(
                                            "v-card-actions",
                                            {
                                              staticClass:
                                                "mt-10 d-flex justify-end",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    light: "",
                                                    color: "tertiary",
                                                    disabled:
                                                      !_vm.selectedProduct ||
                                                      (_vm.numberOfMissingPackages !==
                                                        null &&
                                                        _vm.numberOfMissingPackages >
                                                          0),
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.finishStepProductSelection,
                                                  },
                                                },
                                                [
                                                  _vm._v(" Next "),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        right: "",
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "fa-light fa-chevron-right"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "2" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-center" },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "pa-10",
                                          attrs: {
                                            elevation: "0",
                                            color: "white",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("fa-light fa-user")]
                                              ),
                                              _c("span", [
                                                _vm._v("Your Account"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          !_vm.userAccount
                                            ? [
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "To continue you need to login or sign up."
                                                      ),
                                                    ]),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: { plain: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$auth.signIn()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fa-light fa-right-to-bracket"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " Login or sign-up "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : [
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-form",
                                                      [
                                                        _c(
                                                          "v-container",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                      md: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Username",
                                                                          readonly:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .userAccount
                                                                              .username,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.userAccount,
                                                                                "username",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "userAccount.username",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                      md: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Name",
                                                                          readonly:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .userAccount
                                                                              .name,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.userAccount,
                                                                                "name",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "userAccount.name",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-title",
                                                  { staticClass: "mt-10" },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { left: "" } },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-envelope"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _vm._v("Billing Address"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c("v-divider"),
                                                _vm.billingAddress.object
                                                  ? _c(
                                                      "v-card-text",
                                                      [
                                                        _c(
                                                          "v-form",
                                                          {
                                                            model: {
                                                              value:
                                                                _vm.billingAddressValid,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.billingAddressValid =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "billingAddressValid",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-container",
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "First name *",
                                                                                required:
                                                                                  "",
                                                                                rules:
                                                                                  [
                                                                                    _vm
                                                                                      .$validation
                                                                                      .required,
                                                                                  ],
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .firstName,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "firstName",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.firstName",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Last name *",
                                                                                required:
                                                                                  "",
                                                                                rules:
                                                                                  [
                                                                                    _vm
                                                                                      .$validation
                                                                                      .required,
                                                                                  ],
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .lastName,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "lastName",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.lastName",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Company",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .company,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "company",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.company",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Street *",
                                                                                required:
                                                                                  "",
                                                                                rules:
                                                                                  [
                                                                                    _vm
                                                                                      .$validation
                                                                                      .required,
                                                                                  ],
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .street,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "street",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.street",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Postal code *",
                                                                                required:
                                                                                  "",
                                                                                rules:
                                                                                  [
                                                                                    _vm
                                                                                      .$validation
                                                                                      .required,
                                                                                  ],
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .postalCode,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "postalCode",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.postalCode",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "City *",
                                                                                required:
                                                                                  "",
                                                                                rules:
                                                                                  [
                                                                                    _vm
                                                                                      .$validation
                                                                                      .required,
                                                                                  ],
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .city,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "city",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.city",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "E-Mail *",
                                                                                required:
                                                                                  "",
                                                                                rules:
                                                                                  [
                                                                                    _vm
                                                                                      .$validation
                                                                                      .required,
                                                                                    _vm
                                                                                      .$validation
                                                                                      .mail,
                                                                                  ],
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .mail,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "mail",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.mail",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Mobile phone",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .mobile,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "mobile",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.mobile",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Landline",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .landline,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "landline",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.landline",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Fax",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .fax,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "fax",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.fax",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Web",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .billingAddress
                                                                                    .object
                                                                                    .web,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .billingAddress
                                                                                        .object,
                                                                                      "web",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "billingAddress.object.web",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "v-container",
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  align:
                                                                    "center",
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-progress-circular",
                                                                  {
                                                                    attrs: {
                                                                      size: 50,
                                                                      indeterminate:
                                                                        "",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                          _c(
                                            "v-card-actions",
                                            {
                                              staticClass:
                                                "mt-10 d-flex justify-space-between",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    light: "",
                                                    color: "tertiary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.step = 1
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "fa-light fa-chevron-left"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" Back "),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    light: "",
                                                    color: "tertiary",
                                                    disabled:
                                                      !_vm.billingAddressValid,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.finishStepBillingAddress,
                                                  },
                                                },
                                                [
                                                  _vm._v(" Next "),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        right: "",
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "fa-light fa-chevron-right"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "3" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-center" },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "pa-10",
                                          attrs: {
                                            elevation: "0",
                                            color: "white",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [
                                                  _vm._v(
                                                    "fa-light fa-circle-info"
                                                  ),
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v("Subscription Summary"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list",
                                            { attrs: { color: "transparent" } },
                                            [
                                              _vm.selectedProduct
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      attrs: { "two-line": "" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedProduct
                                                                      .name
                                                                  ) +
                                                                  " Subscription "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedProduct
                                                                      .description
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _vm.selectedProduct
                                                            .price
                                                            ? _c(
                                                                "v-list-item-action-text",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .selectedProduct
                                                                          .price
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _vm
                                                                    .selectedProduct
                                                                    .currency ===
                                                                  "Euro"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "€"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.selectedProduct
                                                            .price
                                                            ? _c(
                                                                "v-list-item-action-text",
                                                                [
                                                                  _vm
                                                                    .selectedProduct
                                                                    .billingPlan ===
                                                                  "Monthly"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "per month"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "v-list-item-action-text"
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.selectedPackages,
                                                function (pkg) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: pkg.key,
                                                      attrs: {
                                                        "two-line": "",
                                                        dense: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    pkg.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    pkg.description
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c(
                                                            "v-list-item-action-text",
                                                            [
                                                              _vm._v(
                                                                " included "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              _vm.selectedProduct &&
                                              _vm.selectedProduct.price
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        {
                                                          attrs: {
                                                            "two-line": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [_vm._v(" VAT ")]
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                " 19% included "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.selectedProduct.price
                                                        ? _c(
                                                            "v-list-item-action",
                                                            [
                                                              _c(
                                                                "v-list-item-action-text",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        parseFloat(
                                                                          (
                                                                            (_vm
                                                                              .selectedProduct
                                                                              .price *
                                                                              19) /
                                                                            100
                                                                          ).toString()
                                                                        ).toFixed(
                                                                          2
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _vm
                                                                    .selectedProduct
                                                                    .currency ===
                                                                  "Euro"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "€"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-action-text",
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "included"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-action-text"
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("v-divider"),
                                              _vm.selectedProduct &&
                                              _vm.selectedProduct.price
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Recurring Total "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.selectedProduct.price
                                                        ? _c(
                                                            "v-list-item-action",
                                                            [
                                                              _c(
                                                                "v-list-item-action-text",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .selectedProduct
                                                                          .price
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _vm
                                                                    .selectedProduct
                                                                    .currency ===
                                                                  "Euro"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "€"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-action-text",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm
                                                                    .selectedProduct
                                                                    .billingPlan ===
                                                                  "Monthly"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "per month"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-action-text"
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "v-card-title",
                                            { staticClass: "mt-10" },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("fa-light fa-section")]
                                              ),
                                              _c("span", [
                                                _vm._v("Conditions"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list",
                                            { attrs: { color: "transparent" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " Subscription start "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c(
                                                        "v-list-item-action-text",
                                                        [_vm._v(" Now ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " Cancellation period "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c(
                                                        "v-list-item-action-text",
                                                        [_vm._v(" Any time ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c("v-checkbox", {
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(" I agree the "),
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                $event.preventDefault()
                                                                _vm.termsVisible = true
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "terms and conditions"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(". "),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.termsAgreed,
                                                  callback: function ($$v) {
                                                    _vm.termsAgreed = $$v
                                                  },
                                                  expression: "termsAgreed",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.planId
                                            ? _c(
                                                "v-card-text",
                                                { staticClass: "mt-4" },
                                                [
                                                  _c("PayPalSubscribeButtons", {
                                                    attrs: {
                                                      planId: _vm.planId,
                                                      payPalSubscription:
                                                        _vm.activePayPalSubscription,
                                                      color: "black",
                                                      shape: "rect",
                                                      layout: "vertical",
                                                      label: "paypal",
                                                      disabled:
                                                        !_vm.termsAgreed,
                                                      createSubscription:
                                                        _vm.createSubscription,
                                                      updateSubscription:
                                                        _vm.updateSubscription,
                                                    },
                                                    on: {
                                                      cancelled:
                                                        _vm.onPaymentCancelled,
                                                      failed:
                                                        _vm.onPaymentFailed,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-card-actions",
                                            {
                                              staticClass:
                                                "mt-10 d-flex justify-space-between",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mx-3",
                                                  attrs: {
                                                    text: "",
                                                    light: "",
                                                    color: "tertiary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.step = 2
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "fa-light fa-chevron-left"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" Back "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "4" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-center" },
                                    [
                                      _vm.result === "Succeeded"
                                        ? _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "pa-10 d-flex flex-column justify-center",
                                              attrs: {
                                                elevation: "0",
                                                color: "white",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    "x-large": "",
                                                    color: "green darken-3",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "fa-light fa-circle-check"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "text-center py-4",
                                                },
                                                [
                                                  _vm._v(
                                                    " Subscription successful! "
                                                  ),
                                                ]
                                              ),
                                              _vm.activatedSubscription
                                                .object &&
                                              _vm.activatedSubscription.object
                                                .product
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      staticStyle: {
                                                        "max-width": "500px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Thank you for your business with us! You can immediatelly start using the " +
                                                          _vm._s(
                                                            _vm
                                                              .activatedSubscription
                                                              .object.product
                                                              .name
                                                          ) +
                                                          " subscription now."
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-card-actions",
                                                {
                                                  staticClass: "justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "px-5",
                                                      attrs: {
                                                        text: "",
                                                        dark: "",
                                                        color: "tertiary",
                                                        to: { name: "Search" },
                                                      },
                                                    },
                                                    [_vm._v(" Start Now ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.result === "UnexpectedState"
                                        ? _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "pa-10 d-flex flex-column justify-center",
                                              attrs: {
                                                elevation: "0",
                                                color: "white",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    "x-large": "",
                                                    color: "tertiary",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "fa-light fa-hourglass-half"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "text-center py-4",
                                                },
                                                [
                                                  _vm._v(
                                                    " Waiting for activation "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: {
                                                    "max-width": "500px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Please wait for PayPal to activate the subscription. Please contact us if nothing happens. "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-card-actions",
                                                {
                                                  staticClass: "justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "px-5",
                                                      attrs: {
                                                        text: "",
                                                        dark: "",
                                                        color: "tertiary",
                                                        loading:
                                                          _vm
                                                            .activatedSubscription
                                                            .isBusy,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.refreshActivatedSubscription,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            left: "",
                                                            color: "tertiary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa-light fa-arrows-rotate"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" Refresh "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.result === "Cancelled"
                                        ? _c(
                                            "v-alert",
                                            {
                                              staticClass: "pa-10",
                                              staticStyle: {
                                                "background-color":
                                                  "white !important",
                                              },
                                              attrs: {
                                                outlined: "",
                                                border: "left",
                                                icon: "fa-light fa-xmark",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _c("h2", [
                                                _vm._v(" Transaction Aborted "),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  " You have successfully aborted the transaction. "
                                                ),
                                              ]),
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass: "mt-10",
                                                  attrs: {
                                                    "justify-center": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        light: "",
                                                      },
                                                      on: {
                                                        click: _vm.restart,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "fa-light fa-arrow-rotate-left"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" Start again "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.result === "ErrorPayPal"
                                        ? _c(
                                            "v-alert",
                                            {
                                              staticClass: "pa-10",
                                              staticStyle: {
                                                "background-color":
                                                  "white !important",
                                              },
                                              attrs: {
                                                outlined: "",
                                                border: "left",
                                                type: "error",
                                                icon: "fa-light fa-triangle-exclamation",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _c("h2", [
                                                _vm._v(" Payment Failed "),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  " PayPal failed to complete the payment. Please try again. "
                                                ),
                                              ]),
                                              _vm.payPalError
                                                ? _c(
                                                    "v-expansion-panels",
                                                    { staticClass: "my-5" },
                                                    [
                                                      _c(
                                                        "v-expansion-panel",
                                                        [
                                                          _c(
                                                            "v-expansion-panel-header",
                                                            [
                                                              _c("h3", [
                                                                _vm._v(
                                                                  "Technical Details"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-expansion-panel-content",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.payPalError
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass: "mt-10",
                                                  attrs: {
                                                    "justify-center": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        light: "",
                                                      },
                                                      on: {
                                                        click: _vm.restart,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "fa-light fa-arrow-rotate-left"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" Try Again "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.result === "ErrorBraendz"
                                        ? _c(
                                            "v-alert",
                                            {
                                              staticClass: "pa-10",
                                              staticStyle: {
                                                "background-color":
                                                  "white !important",
                                              },
                                              attrs: {
                                                outlined: "",
                                                border: "left",
                                                type: "error",
                                                icon: "fa-light fa-triangle-exclamation",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _c("h2", [
                                                _vm._v(" Activation Failed "),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  " The payment completed successfully, but there was a problem while activating your subscription in Braendz."
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " Please do not resubscribe. Please contact our support. "
                                                ),
                                              ]),
                                              _vm.activatedSubscription.error
                                                ? _c(
                                                    "v-expansion-panels",
                                                    { staticClass: "my-5" },
                                                    [
                                                      _c(
                                                        "v-expansion-panel",
                                                        [
                                                          _c(
                                                            "v-expansion-panel-header",
                                                            [
                                                              _c("h3", [
                                                                _vm._v(
                                                                  "Technical Details"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-expansion-panel-content",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .activatedSubscription
                                                                      .error
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass: "mt-10",
                                                  attrs: {
                                                    "justify-center": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        light: "",
                                                        to: { name: "Imprint" },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "fa-light fa-phone"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" Contact "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("TermsPopup", {
        model: {
          value: _vm.termsVisible,
          callback: function ($$v) {
            _vm.termsVisible = $$v
          },
          expression: "termsVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }