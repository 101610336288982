var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        elevation: "0",
        color: "transparent",
        disabled: _vm.disabled,
        flat: "",
      },
    },
    [_c("div", { staticClass: "text-center", attrs: { id: "paypal-buttons" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }