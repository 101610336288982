var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { light: "", elevation: "3", rounded: "", color: "white" } },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-no-wrap justify-space-between" },
        [
          _c(
            "div",
            [
              _c("v-card-title", [
                _c("h1", [_vm._v(_vm._s(_vm.product.name))]),
              ]),
              _vm.product.description
                ? _c("v-card-subtitle", [
                    _vm._v(" " + _vm._s(_vm.product.description) + " "),
                  ])
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _vm.action === "Subscribe"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            to: {
                              name: "Subscribe",
                              query: { product: _vm.product.key },
                            },
                          },
                        },
                        [_vm._v(" Subscribe ")]
                      )
                    : _vm.action === "GetStarted"
                    ? _c(
                        "v-btn",
                        { attrs: { text: "", to: { name: "Search" } } },
                        [_vm._v(" Get started! ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-avatar",
            { staticClass: "ma-3", attrs: { size: "125", tile: "" } },
            [
              _vm.product.image
                ? _c(
                    "v-icon",
                    { attrs: { "x-large": "", color: "tertiary" } },
                    [_vm._v("fa-light " + _vm._s(_vm.product.image))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }